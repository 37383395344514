@import "~tailwindcss/base";
@import "~tailwindcss/components";
@import "~tailwindcss/utilities";
@import "./utils/index";

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-family: Lato, sans-serif;
}

ol,
ul,
dl {
  margin: 0;
}

// ul li {
//   list-style: none;
// }

a {
  color: inherit;
  text-decoration: none;
}

body {
  background-color: #fbfbfb !important;
}

@layer base {
  ul li {
    list-style: disc;
  }

  ol li {
    list-style: list;
  }
}
